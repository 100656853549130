<template>
  <div>
    <div v-for="post in posts" v-bind:key="post.id">
      <div class="post">
        <div class="post-title">{{ post.title.rendered }}</div>
        <div class="post-desc">
          {{ post.content.rendered.replace(regex, "") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      posts: null,
      regex: /<(.|\n)*?>/g,
    };
  },
  async created() {
    // GET request using fetch with async/await
    const response = await fetch(
      "https://turpinlandscape.com/portal/wp-json/wp/v2/posts"
    );
    const data = await response.json();
    this.posts = data;
  },
});
</script>
